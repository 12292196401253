import React from "react"
import HomeLayout from "../layout/homeLayout"
import SEO from "../components/seo"

function Contact() {
  return (
    <HomeLayout>
      <SEO title="Contact us | mGamer Free online HTML 5 games" />
      <div style={{ padding: "20px" }}>
        Please contact us via email at :{" "}
        <a href="mailto: sdsdeveloper@gmail.com">sdsdeveloper@gmail.com</a>
      </div>
    </HomeLayout>
  )
}

export default Contact
